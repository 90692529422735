/* Style the container for inputs */
.container {
  background-color: #f1f1f1;
  padding: 20px;
}

/* The message box is shown when the user clicks on the password field */
#confirmPassId {
  width: 100%;
  display: none;
  background: #f1f1f1;
  color: #000;
  position: absolute;
  z-index: 20;
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
}

#confirmPassId p {
  padding: 4px 35px;
  font-size: 16px;
}

#newPassId {
  width: 100%;
  display: none;
  background: #f1f1f1;
  color: #000;
  position: absolute;
  z-index: 20;
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
}

#newPassId p {
  padding: 4px 35px;
  font-size: 16px;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: green;
}

.valid:before {
  position: relative;
  left: -16px;
  content: "✔";
  margin-left: -18px;
}

/* Add a red text color and an "x" when the requirements are wrong */
.invalid {
  color: red;
  margin-left: -18px;
}

.invalid:before {
  position: relative;
  left: -16px;
  content: "✖";
}
