/* Regular Weight */
/* @font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/Product Sans Regular.ttf");
  font-display: swap;
} */

/* Bold Weight */
/* @font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/Product Sans Bold.ttf");
  font-display: swap;
} */

/* Bold italic Weight */
/* @font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/Product Sans Italic.ttf");
  font-display: swap;
} */
/* regular Weight */
/* @font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/Product Sans Regular.ttf");
  font-display: swap;
} */
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  min-height: 0;
  min-width: 0;
}
/* Dark Blue 
Midnight Blue 
Turqoise Blue
Bright Sky Blue
Light Blue */
/* #001E6D #042C94 #007DC5 #00B9E5 #66C2FF */
:root {
  --toastify-color-success: #001e6d;
  --primary-color: #001e6d;
  --secondary-color: #042c94;
  --tertiary-color: #007dc5;
  --quaternary-color: #00b9e5;
  --quinary-color: #66c2ff;
  --body-text-color: #042c94;
  --body-link-color: #66c2ff;
  --heading-text-color: #042c94;
  --paragraph-text-color: #c9c9c9;
  --section-background-color: #070b24;
  --section-border-color: #66c2ff;
  --header-background-color: #001f6d05;
  --graph-primary-color: #007dc5;
  --graph-secondary-color: #00b9e5;
  --button-backgroud-color: #042c94;
  --tab-text-color: #007dc5;
  --tab-hover-text-color: #007dc5;
  --icon-color: #007dc5;
  --icon-background-color: #007dc5;
  --tooltip-bg-color: #dcecf7;
}

/* TODO: =================================default================================ */
body {
  /* background-image: url("./assets/images/background.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #66c2ff07;
}
html {
  font-family: "Open Sans";
  font-display: swap;
}
.primary-gradient {
  background: linear-gradient(
    134.38deg,
    var(--quaternary-color) 0%,
    var(--tertiary-color) 43.55%,
    var(--secondary-color) 104.51%
  );
}
.btn-shine {
  position: relative;
  overflow: hidden;
}
.btn-shine:after {
  content: "";
  z-index: 1;
  background-color: hsla(0, 0%, 100%, 0.2);
  position: absolute;
  top: -110%;
  bottom: -50%;
  width: 1.25em;
  transform: translate3d(-1000%, 0, 0) rotate(35deg);
}

.btn-shine:hover::after {
  transition: transform 0.45s ease-in-out;
  transform: translate3d(500%, 0, 0) rotate(35deg);
}
.secondary-gradient::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    134.38deg,
    var(--tertiary-color) 0%,
    var(--secondary-color) 43.55%,
    var(--primary-color) 104.51%
  );
  opacity: 30%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.default-border-bottom {
  border-bottom: 1px solid #d3d2fe;
}
.overflow-style-none::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.overflow-style-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.btn-gradient {
  background: linear-gradient(
    134.38deg,
    var(--secondary-color) 0%,
    var(--tertiary-color) 43.55%,
    var(--quaternary-color) 104.51%
  );
}
.btn-gradient:hover {
  background: linear-gradient(
    134.38deg,
    var(--quaternary-color) 0%,
    var(--tertiary-color) 43.55%,
    var(--secondary-color) 104.51%
  );
}
.text-26 {
  font-size: 26px;
  line-height: 31.54px;
}
.text-18 {
  font-size: 18px;
}
.section-shadow {
  box-shadow: 0px 9px 95px 0px #0000000d;
}

.text-gradient {
  background: linear-gradient(
    134.38deg,
    var(--primary-color) 0%,
    var(--secondary-color) 43.55%,
    var(--tertiary-color) 104.51%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.border-gradient {
  border: 1px solid transparent;
  background: linear-gradient(to right, white, white),
    linear-gradient(
      134.38deg,
      var(--primary-color) 0%,
      var(--secondary-color) 43.55%,
      var(--tertiary-color) 104.51%
    );
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}
.signup.btn-loader {
  border: 3px solid hsla(180, 4%, 82%, 0.2);
  border-top-color: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
/* TODO: =================================default end================================ */
/* TODO: =================================update password================================ */
.content-wrapper.thankyou-section {
  background-image: url("./assets/images/shape/update-pass.svg");
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(0);
  visibility: hidden;
}
.content-wrapper.thankyou-section.active {
  transition: all 0.5s ease-in-out;
  transform: scale(1);
  visibility: visible;
}

/* #otp {
  caret-color: transparent;
}
.otp-input-com .input-wrapper:focus-within {
  border: 2px solid #5356fb;
} */

/* TODO: =================================update password end================================ */
/* TODO: =================================layout================================ */

.nft-main-wrapper-layout .nft-sidebar,
.nft-main-wrapper-layout .nft-header-container-wrapper,
.nft-main-wrapper-layout .largest-sidebar {
  transition: all 0.1s cubic-bezier(0.52, 0.35, 0.7, 0.37);
}

.sidebar-logo.enter {
  transform: scale(1);
  width: 100%;
}
.sidebar-logo {
  transform: scale(0);
  width: 0;
  transition: all 0.5s cubic-bezier(0.48, 0.3, 0.37, 0.79);
}
.menu-setting-items .items .item .item-content {
  transform: scale(0);
  transition: all 0.5s cubic-bezier(0.48, 0.3, 0.37, 0.79);
}

.menu-setting-items .items .item .item-content.active {
  transform: scale(1);
  transition: all 0.5s cubic-bezier(0.48, 0.3, 0.37, 0.79);
}
.menu-setting-items .heading.active {
  opacity: 100;
  transform: scale(1);
  width: 100%;
  transition: all 0.5s cubic-bezier(0.48, 0.3, 0.37, 0.79);
}
.menu-setting-items .heading {
  opacity: 0;
  transform: scale(0);
  width: 0;
  transition: all 0.5s cubic-bezier(0.48, 0.3, 0.37, 0.79);
}
.signout-btn .signout-btn-content.active {
  opacity: 100;
  transform: scale(1);
  width: 100%;
  transition: all 0.5s cubic-bezier(0.48, 0.3, 0.37, 0.79);
}
.signout-btn .signout-btn-content {
  opacity: 0;
  transform: scale(0);
  width: 0;
  transition: all 0.5s cubic-bezier(0.48, 0.3, 0.37, 0.79);
}
.balance-dropdown::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-right: 25px solid transparent;
  border-left: 25px solid transparent;
  border-bottom: 30px solid white;
  position: absolute;
  top: -28px;
  left: 50%;
}
.notification-dropdown::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-right: 25px solid transparent;
  border-left: 25px solid transparent;
  border-bottom: 30px solid white;
  position: absolute;
  top: -28px;
  left: 74%;
}
.profile-dropdown::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-right: 25px solid transparent;
  border-left: 25px solid transparent;
  border-bottom: 30px solid white;
  position: absolute;
  top: -28px;
  left: 57%;
}

.balance-dropdown {
  top: 16rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0s;
}
.balance-dropdown.active {
  top: 6rem;
  opacity: 100;
  visibility: visible;
}
.notification-dropdown {
  top: 16rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0s;
}
.notification-dropdown.active {
  top: 6rem;
  opacity: 100;
  visibility: visible;
}
.profile-dropdown {
  top: 16rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) 0s;
}
.profile-dropdown.active {
  top: 6rem;
  opacity: 100;
  visibility: visible;
}
.profile-dropdown,
.notification-dropdown,
.balance-dropdown {
  box-shadow: 19px 11px 127px 0px #00000030;
}
.miniLineChart canvas {
  height: 122px;
}
.menu-setting-items .nav-item.active span.item-icon {
  @apply bg-tab-text-color text-white;
}
.menu-setting-items .nav-item.active span.item-content {
  @apply text-tab-text-color;
}
/* TODO: =================================layout end================================ */
/* TODO: =================================HomePage ================================ */
.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: white !important;
  border: 1px solid white !important;
  border-radius: 100% !important;

  width: 18px !important;
  height: 18px !important;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.home-page-wrapper .hero-slider .slick-slider .slick-dots li {
  margin: 0;
}
.home-page-wrapper
  .hero-slider
  .slick-slider
  .slick-dots
  li.slick-active
  button {
  background: white;
}
.home-page-wrapper .hero-slider .slick-slider .slick-dots li button {
  background: none;
  border: 1px solid white;
  border-radius: 100%;
  width: 8px;
  height: 8px;
}
.home-page-wrapper .hero-slider .slick-slider .slick-dots li button:before {
  content: "";
  width: 100%;
  height: 100%;
}
.trending-slider-left-btn::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    134.38deg,
    var(--tertiary-color) 0%,
    var(--secondary-color) 43.55%,
    var(--primary-color) 104.51%
  );
  z-index: -1;
  opacity: 30%;
}
.card-style-one .content .thumbnail {
  background-repeat: no-repeat;
  background-size: cover;
}
.card-style-one {
  box-shadow: 0px 9px 95px 0px #0000000d;
}

.trending-products .slick-slide > div {
  margin: 0 16px;
}

/* the parent */
.trending-products .slick-list {
  margin: 0 -16px;
}
.update-table table tr:last-child {
  border-bottom: none;
}
/* TODO: =================================HomePage end================================ */
/* TODO: =================================my wallet end================================ */
.circle-count {
  display: flex;
}

.circle-count .percent {
  position: relative;
}

.circle-count svg {
  position: relative;
  width: 74px;
  height: 74px;
  transform: rotate(-90deg);
}

.circle-count svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #f0f0f0;
  stroke-width: 5;
}

.circle-count .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle-count .number :is(h3, span) {
  font-weight: 700;
  font-size: 20px;
  color: white;
}

.circle-count .title h2 {
  margin: 25px 0 0;
}

.circle-count:nth-child(1) svg circle:last-of-type {
  stroke: var(--secondary-color);
}

.circle-count:nth-child(2) svg circle:last-of-type {
  stroke: #66c2ff;
}
.circle-count:nth-child(3) svg circle:last-of-type {
  stroke: #00b9e5;
}

.circle-count svg circle:last-of-type {
  stroke-dasharray: 156px;
  stroke-dashoffset: calc(156px - (156px * var(--percent)) / 100);
}
.recent-transaction-widget .content ul li:last-child {
  border-bottom: none;
}
/* TODO: =================================my wallet end================================ */
/* TODO: =================================SegMint NFT end================================ */
.drop-product-upload {
  background-color: #fafafa;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%235356FB29' stroke-width='1' stroke-dasharray='8%2c8' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
}
.drop-product-upload.highlight {
  border: 2px dashed var(--tertiary-color);
}
.field-title {
  @apply text-xl font-bold tracking-wide text-dark-gray;
}
.switch-btn {
  transition: all 0.5s ease-in-out;
  background-color: #878f9a;
}
.switch-btn.active {
  background-color: #27ae60;
}
.switch-btn .inner-circle {
  transition: all 0.5s ease-in-out;
  transform: translate(1.5px, 1.5px);
}
.switch-btn.active .inner-circle {
  transform: translate(24.5px, 1.5px);
}
.preview-upload-product {
  background-image: url("./assets/images/background-2.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

/* TODO: =================================SegMint NFT end================================ */
/* TODO: =================================setting ================================ */
.notification-page .content-item .notifications {
  @apply flex space-x-4 items-center;
}
.notification-page
  .content-item
  .notifications
  .notification-page
  .content-item
  .notifications {
  @apply mb-0;
}
.notification-setting-tab .notification-settings-items li:last-child {
  border-bottom: none;
}
.faq-tab .accordion-items .accordion-item:first-child .accordion-title-bar {
  padding-top: 0;
}
.faq-tab
  .accordion-items
  .accordion-item:last-child
  .accordion-body
  .accordion-body-content {
  padding-bottom: 20px;
}
.faq-tab .accordion-title-bar {
  background: #ffffff;
  position: relative;
  z-index: 50;
}
.faq-tab .accordion-title-bar .accordion-title-icon .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(0deg);
  visibility: hidden;
}
.faq-tab .accordion-title-bar .accordion-title-icon .vertical.active {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
  visibility: visible;
}
.faq-tab .accordion-body {
  transition: all 0.2s ease-in-out;
  max-height: 0;
  opacity: 0;
  transform: translateY(-220px);
  overflow: hidden;
}
.faq-tab .accordion-body.active {
  transition: all 0.5s ease-in-out;
  max-height: 1444px;
  transform: translateY(0);
  opacity: 100;
}
/* TODO: =================================setting end================================= */
/* TODO: =================================modal================================= */
.children-element {
  overflow: auto;
  top: 60%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.modal-com {
  transition: all 0.1s ease-in-out;
}
/* TODO: =================================modal end================================= */
/* TODO: =================================login ================================= */
.btn-login {
  width: 100%;
  height: 58px;
  background-color: button-backgroud-color;
}
.btn-login.active {
  transition: all 0.5s ease-in-out;
  width: 58px;
  height: 58px;
  background-color: var(--tertiary-color);
}

/* TODO: =================================login end================================= */
/* TODO: ================================= profile================================= */
.nft-authprofile-wrapper .content-wrapper-profile-only .auth,
.nft-userprofile-wrapper .content-wrapper-profile-only .auth {
  margin-top: -120px;
}

/* TODO: =================================profile end================================= */
/* TODO: ================================= settings================================= */

/* TODO: =================================settings end================================= */
/*
TODO: components ===========================
*/

.drop-down-com .drop-down-content {
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  top: 150px;
}
.drop-down-com .drop-down-content.active {
  opacity: 100;
  visibility: visible;
  top: 100%;
}
.product-options .drop-down-content {
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  top: 150px;
  right: 40px;
}
.product-options .drop-down-content.active {
  opacity: 100;
  visibility: visible;
  top: 0;
}
.product-two-options .drop-down-content {
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  top: 150px;
  right: 0;
}
.product-two-options .drop-down-content.active {
  opacity: 100;
  visibility: visible;
  top: calc(100% + 10px);
}
.rate-statics-dropdown {
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  top: 150px;
  left: 0;
}
.rate-statics-dropdown.active {
  opacity: 100;
  visibility: visible;
  top: calc(100% + 10px);
}
.Update-table-dropdown :is(.text-sm) {
  @apply text-base;
}
/* 
FracX
*/
.opensea-required-p {
  font-weight: 500;
  font-size: 12px;
  color: rgb(112, 122, 131);
}
.opensea-required-p .opensea-required-mark {
  color: #00b9e5;
}
/* .opensea-image-label-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
} */
.opensea-image-label-div .opensea-image-label {
  color: rgb(53, 56, 64);
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
}
.opensea-image-input-div {
  position: relative;
  padding: 4px;
  cursor: pointer;
  border: 3px dashed rgb(204, 204, 204);
  border-radius: 10px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 257px;
  width: 350px;
}
.opensea-image-input-div .opensea-image-input {
  appearance: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  right: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
}
.opensea-image-input-div .opensea-image-icon {
  color: rgb(204, 204, 204);
  text-rendering: optimizelegibility;
  font-feature-settings: "liga" !important;
  font-size: 84px !important;
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-display: swap;
}
.opensea-image-input-div .opensea-image-offset {
  position: absolute;
  inset: 4px;
  z-index: 80;
  opacity: 0;
  /* height: 0px; */
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
}
.opensea-image-input-div .opensea-image-offset:hover {
  position: absolute;
  inset: 4px;
  z-index: 80;
  opacity: 1;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
}
.opensea-image-input-div .opensea-preview-image-span {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
}
.opensea-image-input-div .opensea-preview-image-span .opensea-preview-image {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: scale-down;
}
.opensea-input-prefix {
  -webkit-box-align: center;
  align-items: center;
  background-color: transparent;
  color: rgb(112, 122, 131);
  display: flex;
  padding-left: 12px;
}
.opensea-input-main {
  border-radius: 10px;
  border: 2px solid rgb(229, 232, 235);
  display: flex;
  position: relative;
}
.opensea-input {
  background-color: transparent;
  border: none;
  flex: 1 0 0%;
  height: 48px;
  outline: none;
  padding: 0px 12px 0px 0px;
  min-width: 0px;
}
.opensea-mandatary-mark::after {
  content: " *";
  color: #00b9e5;
}
.opensea-contract-link {
  color: var(--tertiary-color);
  text-decoration: underline;
}
/* 
Property Add 
*/
.property-add {
  margin-top: 16px;
  border-bottom: 1px solid rgb(229, 232, 235);
  padding-bottom: 16px;
}
.property-add .property-add-item {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.property-add .property-add-item .property-add-content {
  display: flex;
}
/* .property-add .property-add-item .property-add-content .property-add-svg {
} */
.property-add
  .property-add-item
  .property-add-content
  .property-add-content-main {
  margin-left: 16px;
}
/* .property-add
  .property-add-item
  .property-add-content
  .property-add-content-main
  .property-add-header {
}
.property-add
  .property-add-item
  .property-add-content
  .property-add-content-main
  .property-add-content-label {
} */

.property-add-side .property-add-button {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 12px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  padding: 17px;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(229, 232, 235);
  color: var(--tertiary-color);
}
/* 
Upload Product
*/
.upload-property-add-head {
  border-bottom: none;
}
.upload-property-add-head .upload-property-add-head-row {
  margin-bottom: 20px;
}
.upload-property-add-head
  .upload-property-add-head-row
  .upload-property-add-head-column {
  text-align: start;
  padding: 6px 13px;
}
/* .upload-property-add-head
  .upload-property-add-head-row
  .upload-property-add-head-column:first-of-type {
  padding-left: 70px;
  border: 1px solid #000;
} */
.upload-property-add-body {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.upload-property-add-body .upload-property-add-body-column {
  padding: 0px 10px 20px;
}
.upload-property-add-body
  .upload-property-add-body-column
  .upload-property-add-inputmain {
  border-radius: 10px;
  border: 2px solid rgb(229, 232, 235);
  display: flex;
  position: relative;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.upload-property-add-body
  .upload-property-add-body-column
  .upload-property-add-body-remove {
  border-bottom-left-radius: 10px;
  border-right: 2px solid rgb(229, 232, 235);
  border-top-left-radius: 10px;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(112, 122, 131);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  background-color: rgb(251, 253, 255);
}
.upload-property-add-body
  .upload-property-add-body-column
  .upload-property-add-body-remove
  .upload-property-add-body-remove-button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 100%;
  font-family: inherit;
  font-display: swap;
  border: 0px;
  background: inherit;
}
.upload-property-add-body
  .upload-property-add-body-column
  .upload-property-add-body-remove
  .upload-property-add-body-remove-button
  .upload-property-add-body-cross-svg {
  margin: 12px;
  cursor: pointer;
}
.upload-property-add-body
  .upload-property-add-body-column
  .upload-property-add-body-type {
  background-color: transparent;
  border: none;
  flex: 1 0 0%;
  height: 48px;
  outline: none;
  padding: 0px 12px 0px 0px;
  min-width: 0px;
  font-size: 20px;
}
.upload-property-add-body
  .upload-property-add-body-column
  .upload-property-add-inputmain-name {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-radius: 10px;
  border: 2px solid rgb(229, 232, 235);
  display: flex;
  position: relative;
}
.upload-property-add-button {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 12px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  padding: 17px 24px;
  background-color: rgb(255, 255, 255);
  border: 2px solid var(--tertiary-color);
  color: var(--tertiary-color);
  /* margin-left: 25px; */
  margin-bottom: 24px;
}
.upload-property-add-footer {
  display: flex;
  width: 100%;
  column-gap: 12px;
  padding-left: 12px;
  padding-right: 12px;
  /* padding-bottom: 24px; */
}
.upload-property-add-footer .upload-property-save-button {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 12px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  padding: 17px 24px;
  background-color: var(--tertiary-color);
  border: 2px solid var(--tertiary-color);
  color: rgb(255, 255, 255);
}

/* 
  Properties
*/
.properties-add {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;
}
.properties-add .properties-add-box {
  color: var(--tertiary-color);
  text-decoration: none;
  margin: 5px;
  width: 150px;
  background-color: rgba(21, 178, 229, 0.06);
  border-radius: 6px;
  border: 1px solid #00b9e5;
  padding: 10px;
  text-align: center;
}
.properties-add .properties-add-box .properties-add-type {
  color: #00b9e5;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}
.properties-add .properties-add-box .properties-add-value {
  color: rgb(53, 56, 64);
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* My Wallet
 */
.my-nft-preview {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
/*
/*
TODO: Responsive ===========================
*/

@media (max-width: 1536px) {
  .trending-products .slick-slide > div {
    margin: 0 10px;
  }
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
  .content-wrapper.thankyou-section {
    background-image: none;
  }
  .children-element {
    overflow: auto;
    top: 60%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 768px) {
  .profile-dropdown::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-right: 25px solid transparent;
    border-left: 25px solid transparent;
    border-bottom: 30px solid white;
    position: absolute;
    top: -28px;
    left: 78%;
  }
  .children-element {
    overflow: auto;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 640px) {
}
@media (max-width: 426px) {
  .nft-authprofile-wrapper .content-wrapper-profile-only .auth,
  .nft-userprofile-wrapper .content-wrapper-profile-only .auth {
    margin-top: -70px;
  }
}
@media (max-width: 376px) {
  .notification-page .content-item .notifications {
    @apply block space-x-0;
  }
  .notification-page .content-item .notifications .icon {
    @apply mb-2;
  }
}

.error-message {
  color: red;
  font-family: "Open Sans";
  font-display: swap;
  font-size: 17px;
  line-height: 12px;
  text-align: left;
  font-weight: 400;
  margin-top: 8px;
  margin-left: 8px;
  display: block;
}

.shepherd-content {
  background: var(--tertiary-color) !important;
}

.shepherd-text {
  color: white !important;
}

.shepherd-arrow:before {
  background: var(--tertiary-color) !important;
  content: "";
  transform: rotate(45deg);
}

.walletchatwidget {
  display: flex;
  position: fixed;
  right: 0px !important;
  bottom: 0px !important;
  z-index: 1000;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: var(--tertiary-color) !important;
}

.crossed-line {
  width: 80px;
  height: 47px;
  border-bottom: 3px solid #7150fa;
  -webkit-transform: translateY(20px) translateX(5px) rotate(-70deg);
  position: absolute;
  top: -13px;
  left: -52px;

  border-radius: 1px;
}

#triangle-topleft {
  width: 0;
  height: 0;
  border-top: 80px solid #eeebfe;
  border-right: 30px solid transparent;

  position: absolute;
  left: -1px;
  top: -2px;
}

.slider-css {
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
}

@media (min-width: 1000px) {
  .slider-css {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
  }
}

/* Tab to Dropdown */

.tab {
  overflow: hidden;
}
.tab span {
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 12px;
  transition: 0.3s;
  font-size: 1.125rem;
}
.tab span.active {
  color: #fff;
}
.tabcontent {
  display: none;
  border-top: none;
}
.is-hidden {
  display: none !important;
}
.is-desktop {
  display: block;
}
@media (max-width: 768px) {
  .is-desktop {
    display: none !important;
  }
}
.is-mobile {
  display: none !important;
}
@media (max-width: 768px) {
  .is-mobile {
    display: block !important;
  }
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Remove default arrow */
  width: 100%;
  padding: 1rem;
  font-size: 1.125rem;
  border: none;
}
select::-ms-expand {
  display: none;
}
.select {
  position: relative;
}
@media (max-width: 768px) {
  .select:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke='none'%3E%3Cpath d='M21.293,12.293,16,17.586l-5.293-5.293a1,1,0,0,0-1.414,1.414l6,6a1,1,0,0,0,1.414,0l6-6a1,1,0,0,0-1.414-1.414Z' fill='%23000000'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    pointer-events: none;
    width: 32px;
    height: 32px;
  }
}

.css-13cymwt-control {
  padding: 3px !important;
  border: 2px solid silver !important;
  /* border-color: rgb(148 163 184 / var(--tw-border-opacity)); */
}

.RRT__panel {
  border: 0px solid !important;
  margin-top: 0px !important;
}

.RRT__tab {
  background-color: transparent !important;
  border: 0px solid !important;
}

.RRT__tab:hover {
  border-bottom: 2px solid var(--tertiary-color) !important;
  color: var(--tertiary-color) !important;
}

.RRT__showmore-l {
  background-color: #f5f5f5 !important;
}

.RRT__tab--selected {
  border-bottom: 2px solid var(--tertiary-color) !important;
  color: var(--tertiary-color) !important;
}

.RRT__showmore {
  background-color: transparent !important;
  border: 2px solid var(--tertiary-color) !important;
  border-radius: 8px !important;
}

.RRT__showmore-list--opened {
  background-color: #f5f5f5 !important;
  border-radius: 8px !important;
  margin-top: 4px !important;
}

.RRT__showmore-label--selected {
  background-color: transparent !important;
}

.dynamic-connect-button {
  width: -webkit-fill-available;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}
